import Head from "next/head";
export default function MetaHeader({
  title,
  description,
  author,
  image,
}: {
  title?: string;
  description?: string;
  author?: string;
  image?: string;
}) {
  //returns meta information.
  return (
    <Head>
      <title>{title || "DecodedBlog"}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={description || "Read on DecodedBlog"} />
      <meta name="author" content={author} />
      <meta
        property="og:image"
        content={image || "https://decodedblog.com/skullWithBooks.jpg"}
      />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta charSet="utf-8" />
    </Head>
  );
}

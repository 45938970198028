import { CSSProperties, ReactNode, useState } from "react";
import { Color } from "../theme/theme";
export default function GenericButton({
  children,
  onClick,
  selected,
  disabled,
  style,
}: {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
}): JSX.Element {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        border: "solid",
        borderColor: disabled ? Color.grey : Color.dark,
        width: "fit-content",
        padding: "5px",
        fontSize: "14px",
        fontWeight: "400",
        ...(disabled
          ? {}
          : {
              backgroundColor: selected || hover ? Color.sky : null,
              cursor: "pointer",
            }),
        ...style,
      }}
      onMouseDown={(e) => {
        onClick && !disabled && onClick(e);
      }}
    >
      {children}
    </div>
  );
}

import { useState } from "react";
import { Color } from "../../theme/theme";
import ImageUpload from "../ImageUpload/ImageUpload";

export default function ProfilePicture({
  isEditable,
  image,
  onImageChange,
}: {
  isEditable: boolean;
  image: string;
  onImageChange?: (o: string) => void;
}) {
  const [isEditingPhoto, setIsEditingPhoto] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
      onMouseEnter={() => {
        isEditable && setIsEditingPhoto(true);
      }}
      onMouseLeave={() => {
        setIsEditingPhoto(false);
      }}
      onClick={() => isEditable && setIsEditingPhoto(true)}
    >
      <img
        style={{
          width: "100%",
          position: "absolute",
          height: "100%",
          objectFit: "cover",
          borderRadius: "50%",
        }}
        src={image}
      />
      {
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: `${Color.dark}AA`,
            fontSize: "48px",
            color: Color.white,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            visibility: isEditable && isEditingPhoto ? "visible" : "hidden",
          }}
        >
          <ImageUpload urlChange={(o) => onImageChange(o)}></ImageUpload>
        </div>
      }
    </div>
  );
}

import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Color } from "../theme/theme";
export default function Dropdown({
  children,
  trigger,
}: {
  children: ReactNode;
  trigger: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <div ref={ref}>
      <div onClick={() => setOpen(true)}>{trigger}</div>
      {open && (
        <div
          onClick={() => setOpen(false)}
          style={{
            position: "absolute",
            background: Color.white,
            border: "solid",
            borderColor: Color.dark,
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

function useOutsideClick(
  ref: MutableRefObject<HTMLDivElement>,
  callback: () => void
) {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
}

import { useEffect, useState } from "react";

//todo sperate out visual viewport
export function useViewPort() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [keyboardPosition, setKeyboardPosition] = useState(0);
  const handleWindowSizeChange = () => {
    setWidth(
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    );
    setHeight(
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    );
    setKeyboardPosition(
      window.visualViewport.offsetTop + window.visualViewport.height
    );
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.visualViewport.addEventListener("resize", handleWindowSizeChange);
    window.visualViewport.addEventListener("scroll", handleWindowSizeChange);
    window.addEventListener("resize", handleWindowSizeChange);
    /*window.addEventListener("scroll", handleWindowSizeChange, {
      passive: true,
    });*/
    return () => {
      window.visualViewport.removeEventListener(
        "resize",
        handleWindowSizeChange
      );
      window.removeEventListener("resize", handleWindowSizeChange);
      document.removeEventListener("scroll", handleWindowSizeChange);
    };
  }, []);
  return [width, height, keyboardPosition] as const;
}

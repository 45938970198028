import type * as CSS from "csstype";
import { ReactNode } from "react";
import { useIsMobile } from "../hooks/isMobile.hook";
import { useViewPort } from "../hooks/useViewport";
import { Color } from "../theme/theme";
import NavBar from "./NavBar";

export default function PageTemplate({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSS.Properties;
}) {
  const isMobile = useIsMobile();
  const [width, height] = useViewPort();

  return (
    <>
      <NavBar />
      <div
        style={{
          paddingLeft: "48px",
          paddingRight: "48px",
          paddingTop: "48px",
          height: `calc(${height}px - ${isMobile ? "95px" : "45px"})`,
          color: Color.dark,
          ...style,
        }}
      >
        {children}
      </div>
    </>
  );
}

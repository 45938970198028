import { ChangeEvent, useRef } from "react";
import GenericButton from "../Button";
import { uploadPhoto } from "./imageUpload.utilities";

export default function ImageUpload({
  urlChange,
}: {
  urlChange: (o: string) => void;
}) {
  const fileInputRef = useRef() as any; ///todo

  async function onUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.[0]) {
      const res = await uploadPhoto(e.target.files?.[0]);
      urlChange(res);
    }
  }
  return (
    <>
      <GenericButton onClick={() => fileInputRef.current.click()}>
        Upload
      </GenericButton>
      <input
        onChange={onUpload}
        multiple={false}
        ref={fileInputRef}
        type="file"
        accept="image/png, image/jpeg"
        hidden
      />
    </>
  );
}

import {ReactNode, useState} from 'react';
import type * as CSS from "csstype";

export default function Hover({children, onClick, style, initialColor}:{
  children: ReactNode,
  onClick?: (e: React.MouseEvent<HTMLElement>)=>void,
  style?: CSS.Properties,
  initialColor?: string,
}): JSX.Element{
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor:hover?(`rgb(18 44 52 / 10%)`):(initialColor),
        cursor:'pointer',
        ...style,
      }}
      onMouseDown={(e)=>{onClick&&onClick(e)}}
    >{children}</div>
  );
}

import Compressor from "compressorjs";

export async function uploadPhoto(file: File) {
  const filename = encodeURIComponent(file.name);
  const fileType = encodeURIComponent(file.type);
  const maxSize = 4194304; //nextjs maximum 4mb need to do presigned s3 urls but fuck that
  const qualityReduction = file.size > maxSize ? 4194304 / file.size : 1;
  const res = (await new Promise(function (resolve, reject) {
    new Compressor(file, {
      quality: qualityReduction,
      success(result) {
        resolve(result as File);
      },
    });
  })) as File;
  const upload = await fetch(
    `/api/upload?file=${filename}&fileType=${fileType}`,
    {
      method: "POST",
      body: res,
    }
  );
  return await upload.json();
}

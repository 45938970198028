import { signIn, signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";
import { MeContext } from "../hooks/context";
import { useIsMobile } from "../hooks/isMobile.hook";
import { Color } from "../theme/theme";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Hover from "./Hover";
import ProfilePicture from "./User/ProfilePicture";

export default function NavBar() {
  const { me } = useContext(MeContext);
  const router = useRouter();
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        borderBottom: "solid",
        borderColor: Color.dark,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: isMobile ? "12px" : "48px",
        paddingRight: isMobile ? "12px" : "48px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: isMobile ? "flex-start" : "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <img
          src="/textlogo.png"
          alt="text logo"
          style={{
            height: isMobile ? "45px" : "21px",
            width: "189px",
            paddingRight: "12px",
            paddingBottom: isMobile ? "12px" : null,
            paddingTop: isMobile ? "12px" : null,
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link href="/">
            <a>
              <Hover
                style={{
                  padding: "12px",
                  ...(router.pathname === "/" && {
                    backgroundColor: Color.dark,
                    color: Color.white,
                  }),
                }}
              >
                Home
              </Hover>
            </a>
          </Link>
          <Link href="/create">
            <a>
              <Hover
                style={{
                  padding: "12px",
                  ...(router.pathname === "/create" && {
                    backgroundColor: Color.dark,
                    color: Color.white,
                  }),
                }}
              >
                Create
              </Hover>
            </a>
          </Link>
          <Link href="/discover">
            <a>
              <Hover
                style={{
                  padding: "12px",
                  ...(router.pathname === "/discover" && {
                    backgroundColor: Color.dark,
                    color: Color.white,
                  }),
                }}
              >
                Discover
              </Hover>
            </a>
          </Link>
          <Link href="/about">
            <a>
              <Hover
                style={{
                  padding: "12px",
                  ...(router.pathname === "/about" && {
                    backgroundColor: Color.dark,
                    color: Color.white,
                  }),
                }}
              >
                About
              </Hover>
            </a>
          </Link>
        </div>
      </div>
      <div>
        {me ? (
          <Dropdown
            trigger={
              <div style={{ width: "36px", height: "36px", cursor: "pointer" }}>
                <ProfilePicture
                  isEditable={false}
                  image={me.image}
                ></ProfilePicture>
              </div>
            }
          >
            <Button style={{ width: "100%" }} onClick={() => signOut()}>
              Sign Out
            </Button>
            <Link href={`/user/${me.username}`}>
              <Button style={{ width: "100%", wordBreak: "keep-all" }}>
                View Profile
              </Button>
            </Link>
          </Dropdown>
        ) : (
          <Button onClick={() => signIn("auth0")}>Sign In</Button>
        )}
      </div>
    </div>
  );
}
